<template>
  <div>
    <window-header></window-header>
    <nav-form></nav-form>
    <section>
      <article style="height: 395px">
        <field
          name="delegacion_id"
          widget="m2o"
          label="Deleg."
          width="165px"
          style="top: 9px; left: 10px"
          :readonly="mode == 'edit'"
        />
        <field
          name="ndoc"
          widget="char"
          searchable
          label="Nº Documento"
          width="110px"
          style="top: 9px; left: 189px"
        />
        <field
          name="fecha"
          widget="date"
          type="date"
          default="today"
          label="Fecha"
          width="100px"
          style="top: 9px; left: 307px"
        />
        <field
          name="almacen_id"
          widget="m2o"
          label="Almacen"
          width="235px"
          style="top: 9px; left: 415px"
          :readonly="mode == 'edit'"
          :filter="
            formData.delegacion_id
              ? [['delegacion_id', '=', formData.delegacion_id.codigo]]
              : null
          "
          @select="onChangeAlmacen"
        />
        <r-tabs
          ref="main-tabs"
          :wt="wt"
          style="top: 55px; height: 340px"
          :buttons="['Líneas', 'Resumen']"
        >
          <div class="tab">
            <field
              ref="lineas"
              name="lineas"
              widget="handsontable"
              :height="295"
              :width="655"
              :minRows="11"
              style="top: 0px; left: 0px; width: 660px"
              :columns="columns"
              :htSettings="htSettingsLineas"
              :readonly="mode != 'new'"
              :fields="[
                { name: 'articulo_id', fields: ['codigo'] },
                'unidades_teorico_a',
                'unidades_teorico_b',
                'unidades_fisico_a',
                'unidades_fisico_b',
                'peso_teorico_a',
                'peso_teorico_b',
                'peso_fisico_a',
                'peso_fisico_b',
              ]"
            />
          </div>
          <div class="tab">
            <div
              style="position: absolute; top: 100px; padding: 10px; width: 100%"
            >
              <table class="tabla-resumen">
                <tr>
                  <th colspan="2">Inventario Físico</th>
                  <th colspan="2">Inventario Teórico</th>
                  <th colspan="3">Discrepancias</th>
                </tr>
                <tr>
                  <th>Unidades</th>
                  <th>Peso</th>
                  <th>Unidades</th>
                  <th>Peso</th>
                  <th>Unidades</th>
                  <th>Peso</th>
                  <th>Líneas</th>
                </tr>
                <template v-if="parseInt(wt)">
                  <tr>
                    <td>{{ unidades_fisico_a }}</td>
                    <td>{{ peso_fisico_a }}</td>
                    <td>{{ unidades_teorico_a }}</td>
                    <td>{{ peso_teorico_a }}</td>
                    <td>{{ unidades_diferencia_a }}</td>
                    <td>{{ peso_diferencia_a }}</td>
                    <td>{{ lineas_diferencia_a }}</td>
                  </tr>
                  <tr class="tabla-resumen-b">
                    <td>{{ unidades_fisico_b }}</td>
                    <td>{{ peso_fisico_b }}</td>
                    <td>{{ unidades_teorico_b }}</td>
                    <td>{{ peso_teorico_b }}</td>
                    <td>{{ unidades_diferencia_b }}</td>
                    <td>{{ peso_diferencia_b }}</td>
                    <td>{{ lineas_diferencia_b }}</td>
                  </tr>
                </template>
                <tr>
                  <td>{{ unidades_fisico }}</td>
                  <td>{{ peso_fisico }}</td>
                  <td>{{ unidades_teorico }}</td>
                  <td>{{ peso_teorico }}</td>
                  <td>{{ unidades_diferencia }}</td>
                  <td>{{ peso_diferencia }}</td>
                  <td>{{ lineas_diferencia }}</td>
                </tr>
              </table>
            </div>
          </div>
        </r-tabs>
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
import Handsontable from "handsontable";
var floatOrZero = function (val) {
  return isFinite(parseFloat(val || 0.0)) ? parseFloat(val || 0.0) : 0.0;
};
var generateRenderer = function (fun) {
  return function (instance, td, row, col, prop, value, cellProperties) {
    let h = instance;
    let args = [...arguments];
    let data = h.getSourceData()[row];
    if (data.articulo_id) {
      args[5] = fun(data);
    }
    Handsontable.renderers.NumericRenderer.apply(this, args);
  };
};
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function () {
    var self = this;
    return {
      title: "Regularización de inventario",
      dbAdapter: "regularizacion",
      primary: "ndoc",
      sequence: { field: "ndoc", name: "regularizacion" },
      defaultData: {
        delegacion_id: self.$utils.misc.filterPropsInObject(
          self.app.config.delegacion_defecto,
          ["codigo", "nombre"]
        ),
        //almacen_id: self.app.config.almacen_defecto || null,
        fecha: new Date().yyyymmdd(),
        //wt: 0,
      },
      //fields: ["wt"],
      wt: 0,
    };
  },
  computed: {
    unidades_fisico: function () {
      return (this.formData.lineas || [])
        .reduce(
          (prev, l) => prev + l.unidades_fisico_a + l.unidades_fisico_b,
          0
        )
        .toFixed(2);
    },
    peso_fisico: function () {
      return (this.formData.lineas || [])
        .reduce((prev, l) => prev + l.peso_fisico_a + l.peso_fisico_b, 0)
        .toFixed(2);
    },
    unidades_teorico: function () {
      return (this.formData.lineas || [])
        .reduce(
          (prev, l) => prev + l.unidades_teorico_a + l.unidades_teorico_b,
          0
        )
        .toFixed(2);
    },
    peso_teorico: function () {
      return (this.formData.lineas || [])
        .reduce((prev, l) => prev + l.peso_teorico_a + l.peso_teorico_b, 0)
        .toFixed(2);
    },
    unidades_diferencia: function () {
      return (this.formData.lineas || [])
        .reduce(
          (prev, l) =>
            prev +
            l.unidades_fisico_a +
            l.unidades_fisico_b -
            l.unidades_teorico_a -
            l.unidades_teorico_b,
          0
        )
        .toFixed(2);
    },
    peso_diferencia: function () {
      return (this.formData.lineas || [])
        .reduce(
          (prev, l) =>
            prev +
            l.peso_fisico_a +
            l.peso_fisico_b -
            l.peso_teorico_a -
            l.peso_teorico_b,
          0
        )
        .toFixed(2);
    },
    unidades_fisico_a: function () {
      return (this.formData.lineas || [])
        .reduce((prev, l) => prev + l.unidades_fisico_a, 0)
        .toFixed(2);
    },
    peso_fisico_a: function () {
      return (this.formData.lineas || [])
        .reduce((prev, l) => prev + l.peso_fisico_a, 0)
        .toFixed(2);
    },
    unidades_teorico_a: function () {
      return (this.formData.lineas || [])
        .reduce((prev, l) => prev + l.unidades_teorico_a, 0)
        .toFixed(2);
    },
    peso_teorico_a: function () {
      return (this.formData.lineas || [])
        .reduce((prev, l) => prev + l.peso_teorico_a, 0)
        .toFixed(2);
    },
    unidades_diferencia_a: function () {
      return (this.formData.lineas || [])
        .reduce(
          (prev, l) => prev + l.unidades_fisico_a - l.unidades_teorico_a,
          0
        )
        .toFixed(2);
    },
    peso_diferencia_a: function () {
      return (this.formData.lineas || [])
        .reduce((prev, l) => prev + l.peso_fisico_a - l.peso_teorico_a, 0)
        .toFixed(2);
    },
    unidades_fisico_b: function () {
      return (this.formData.lineas || [])
        .reduce((prev, l) => prev + l.unidades_fisico_b, 0)
        .toFixed(2);
    },
    peso_fisico_b: function () {
      return (this.formData.lineas || [])
        .reduce((prev, l) => prev + l.peso_fisico_b, 0)
        .toFixed(2);
    },
    unidades_teorico_b: function () {
      return (this.formData.lineas || [])
        .reduce((prev, l) => prev + l.unidades_teorico_b, 0)
        .toFixed(2);
    },
    peso_teorico_b: function () {
      return (this.formData.lineas || [])
        .reduce((prev, l) => prev + l.peso_teorico_b, 0)
        .toFixed(2);
    },
    unidades_diferencia_b: function () {
      return (this.formData.lineas || [])
        .reduce(
          (prev, l) => prev + l.unidades_fisico_b - l.unidades_teorico_b,
          0
        )
        .toFixed(2);
    },
    peso_diferencia_b: function () {
      return (this.formData.lineas || [])
        .reduce((prev, l) => prev + l.peso_fisico_b - l.peso_teorico_b, 0)
        .toFixed(2);
    },
    lineas_diferencia: function () {
      return (this.formData.lineas || [])
        .reduce(
          (prev, l) =>
            prev +
            (l.unidades_fisico_a != l.unidades_teorico_a ||
            l.unidades_fisico_b != l.unidades_teorico_b
              ? 1
              : 0),
          0
        )
        .toFixed(0);
    },
    lineas_diferencia_a: function () {
      return (this.formData.lineas || [])
        .reduce(
          (prev, l) =>
            prev + (l.unidades_fisico_a != l.unidades_teorico_a ? 1 : 0),
          0
        )
        .toFixed(0);
    },
    lineas_diferencia_b: function () {
      return (this.formData.lineas || [])
        .reduce(
          (prev, l) =>
            prev + (l.unidades_fisico_b != l.unidades_teorico_b ? 1 : 0),
          0
        )
        .toFixed(0);
    },
    htSettingsLineas: function () {
      return {
        nestedHeaders: [
          [
            "",
            "",
            "",
            { label: "Teórico", colspan: 2 },
            { label: "Físico", colspan: 2 },
            { label: "Diferencia", colspan: 2 },
          ].concat(
            parseInt(this.wt)
              ? [
                  { label: "Regul.A", colspan: 2 },
                  { label: "Regul.B", colspan: 2 },
                ]
              : []
          ),
          [
            "|||||||||",
            "Artículo",
            "Fecha",
            "Unid.",
            "Peso",
            "Unid.",
            "Peso",
            "Unid.",
            "Peso",
          ].concat(parseInt(this.wt) ? ["Unid.", "Peso", "Unid.", "Peso"] : []),
        ],
        afterSelection: function (r, c, r2, c2) {
          let h = this;
          if (!h.getSourceData()[r].articulo_id && r > 0) {
            h.selectCell(r - 1, c);
          }
        },
        fixedColumnsLeft: 2,
      };
    },
    columns: function () {
      let self = this;
      return [
        { name: "ean13", header: "|||||||||", readOnly: true, width: 80 },
        {
          name: "articulo_id",
          header: "Artículo",
          type: "m2o",
          primary: "codigo",
          label: "codigo",
          readOnly: true,
          width: 80,
        },
        {
          name: "fecha",
          header: "Fecha",
          type: "date2",
          width: 80,
          readOnly: true,
        },
        {
          name: "unidades_teorico",
          header: "Unid. teórico",
          type: "numeric",
          numericFormat: { pattern: "0,0.00" },
          renderer: self.rendererUnidadesTeorico,
          width: 60,
          readOnly: true,
        },
        {
          name: "peso_teorico",
          header: "Peso teórico",
          type: "numeric",
          numericFormat: { pattern: "0,0.00" },
          renderer: self.rendererPesoTeorico,
          width: 60,
          readOnly: true,
        },
        {
          name: "unidades_fisico",
          header: "Unid. físico",
          type: "numeric",
          numericFormat: { pattern: "0,0.00" },
          renderer: self.rendererUnidadesFisico,
          width: 60,
          readOnly: true,
        },
        {
          name: "peso_fisico",
          header: "Peso físico",
          type: "numeric",
          numericFormat: { pattern: "0,0.00" },
          renderer: self.rendererPesoFisico,
          width: 60,
          readOnly: true,
        },
        {
          name: "unidades_diferencia",
          header: "Unid. físico",
          type: "numeric",
          numericFormat: { pattern: "0,0.00" },
          renderer: self.rendererUnidadesDiferencia,
          width: 60,
          readOnly: true,
        },
        {
          name: "peso_diferencia",
          header: "Peso físico",
          type: "numeric",
          numericFormat: { pattern: "0,0.00" },
          renderer: self.rendererPesoDiferencia,
          width: 60,
          readOnly: true,
        },
      ].concat(
        parseInt(this.wt)
          ? [
              {
                name: "unidades_diferencia_a",
                type: "numeric",
                numericFormat: { pattern: "0,0.00" },
                renderer: self.rendererUnidadesDiferenciaA,
                width: 60,
              },
              {
                name: "peso_diferencia_a",
                type: "numeric",
                numericFormat: { pattern: "0,0.00" },
                renderer: self.rendererPesoDiferenciaA,
                width: 60,
              },
              {
                name: "unidades_diferencia_b",
                type: "numeric",
                numericFormat: { pattern: "0,0.00" },
                renderer: self.rendererUnidadesDiferenciaB,
                width: 60,
              },
              {
                name: "peso_diferencia_b",
                type: "numeric",
                numericFormat: { pattern: "0,0.00" },
                renderer: self.rendererPesoDiferenciaB,
                width: 60,
              },
            ]
          : []
      );
    },
  },
  methods: {
    rendererUnidadesTeorico: generateRenderer(
      (data) =>
        floatOrZero(data.unidades_teorico_a) +
        floatOrZero(data.unidades_teorico_b)
    ),
    rendererPesoTeorico: generateRenderer(
      (data) =>
        floatOrZero(data.peso_teorico_a) + floatOrZero(data.peso_teorico_b)
    ),
    rendererUnidadesFisico: generateRenderer(
      (data) =>
        floatOrZero(data.unidades_fisico_a) +
        floatOrZero(data.unidades_fisico_b)
    ),
    rendererPesoFisico: generateRenderer(
      (data) =>
        floatOrZero(data.peso_fisico_a) + floatOrZero(data.peso_fisico_b)
    ),
    rendererUnidadesDiferencia: generateRenderer(
      (data) =>
        floatOrZero(data.unidades_fisico_a) +
        floatOrZero(data.unidades_fisico_b) -
        floatOrZero(data.unidades_teorico_a) -
        floatOrZero(data.unidades_teorico_b)
    ),
    rendererPesoDiferencia: generateRenderer(
      (data) =>
        floatOrZero(data.peso_fisico_a) +
        floatOrZero(data.peso_fisico_b) -
        floatOrZero(data.peso_teorico_a) -
        floatOrZero(data.peso_teorico_b)
    ),
    rendererUnidadesDiferenciaA: generateRenderer(
      (data) =>
        floatOrZero(data.unidades_fisico_a) -
        floatOrZero(data.unidades_teorico_a)
    ),
    rendererPesoDiferenciaA: generateRenderer(
      (data) =>
        floatOrZero(data.peso_fisico_a) - floatOrZero(data.peso_teorico_a)
    ),
    rendererUnidadesDiferenciaB: generateRenderer(
      (data) =>
        floatOrZero(data.unidades_fisico_b) -
        floatOrZero(data.unidades_teorico_b)
    ),
    rendererPesoDiferenciaB: generateRenderer(
      (data) =>
        floatOrZero(data.peso_fisico_b) - floatOrZero(data.peso_teorico_b)
    ),
    onChangeAlmacen(source) {
      var self = this;
      if (source != "edit") return;
      if (self.formData.almacen_id?.codigo) self.actualizarLineas();
      else self.$set(self.formData, "lineas", []);
    },
    actualizarLineas() {
      var self = this;
      if (!self.formData.almacen_id?.codigo) return;
      self.loading = true;
      window.DB.action("almacen", "calcular_regularizacion_inventario", {
        data: {
          almacen_id: self.formData.almacen_id?.codigo,
        },
      })
        .then(function (res) {
          self.$set(self.formData, "lineas", res.lineas);
          self.$set(
            self.formData,
            "entradas",
            res.entradas.map((x) => ({ ndoc: x, _op: "put" }))
          );
        })
        .catch(function (res) {
          self.app.toast(res.msg, "error");
          self.$set(self.formData, "lineas", []);
          self.$set(self.formData, "entradas", []);
        })
        .finally(function () {
          self.loading = false;
        });
    },
  },
  mounted() {
    var self = this;

    self.$refs["main-tabs"].$refs.toolbar.addEventListener(
      "dblclick",
      function (e) {
        if (self.app.session.mode == "a") return;
        if (e.target == self.$refs["main-tabs"].$refs.toolbar) {
          if (
            self.mode != "edit" &&
            self.mode != "new" &&
            self.mode != "search"
          )
            return;
          //self.$set(self.formData, "wt", !parseInt(self.formData.wt) ? 1 : 0);
          self.wt = !parseInt(self.wt) ? 1 : 0;
          self.$refs.lineas.field.columnsData = self.columns;
          self.$emit("change", "wt", "edit");
          if (self.mode == "search") self.setFilter(self.getFilter());
        }
      }
    );

    self.$refs.lineas.field.hotInstance.addHook(
      "beforeChange",
      function (changes, source) {
        if (source == "loadData") return;
        if (!changes) return;
        var h = this;
        changes.forEach(function (change, index) {
          var row = change[0];
          var prop = change[1];
          var oldVal = change[2];
          var newVal = change[3];

          newVal = newVal || 0;
          let data = h.getSourceData()[row];
          // Unidades
          let utb = data["unidades_teorico_b"];
          let uta = data["unidades_teorico_a"];
          let udt =
            data["unidades_fisico_a"] + data["unidades_fisico_b"] - uta - utb;
          if (prop == "unidades_diferencia_a") {
            let uda =
              udt > 0
                ? Math.max(0, Math.min(udt, newVal))
                : Math.min(0, utb + udt, Math.max(udt, newVal, -1 * uta));
            changes.push([row, "unidades_fisico_a", null, uta + uda]);
            changes.push([row, "unidades_fisico_b", null, utb + udt - uda]);
            changes[index][3] = null;
          }
          if (prop == "unidades_diferencia_b") {
            let udb =
              udt > 0
                ? Math.max(0, Math.min(udt, newVal))
                : Math.min(0, uta + udt, Math.max(udt, newVal, -1 * utb));
            changes.push([row, "unidades_fisico_b", null, utb + udb]);
            changes.push([row, "unidades_fisico_a", null, uta + udt - udb]);
            changes[index][3] = null;
          }
          // Peso
          let ptb = data["peso_teorico_b"];
          let pta = data["peso_teorico_a"];
          let pdt = data["peso_fisico_a"] + data["peso_fisico_b"] - pta - ptb;
          if (prop == "peso_diferencia_a") {
            let pda =
              pdt > 0
                ? Math.max(0, Math.min(pdt, newVal))
                : Math.min(0, ptb + pdt, Math.max(pdt, newVal, -1 * pta));
            changes.push([row, "peso_fisico_a", null, pta + pda]);
            changes.push([row, "peso_fisico_b", null, ptb + pdt - pda]);
            changes[index][3] = null;
          }
          if (prop == "peso_diferencia_b") {
            let pdb =
              pdt > 0
                ? Math.max(0, Math.min(pdt, newVal))
                : Math.min(0, pta + pdt, Math.max(pdt, newVal, -1 * ptb));
            changes.push([row, "peso_fisico_b", null, ptb + pdb]);
            changes.push([row, "peso_fisico_a", null, pta + pdt - pdb]);
            changes[index][3] = null;
          }
        });
      }
    );

    /*self.$refs.lineas.field.hotInstance.addHook(
      "afterChange",
      function (changes, source) {
        if (source == "loadData") return;
        if (!changes) return;
        var h = this;
        changes.forEach(function (change, index) {
          var row = change[0];
          var prop = change[1];
          var oldVal = change[2];
          var newVal = change[3];

          if (prop == "sel" && oldVal != newVal) {
            //
          }
        });
      }
    );*/
  },
};
</script>
<style>
.tabla-resumen {
  border: 1px solid #ccc;
  padding: 0;
  border-collapse: collapse;
  text-align: center;
  width: 100%;
}
.tabla-resumen input {
  text-align: center;
}
.tabla-resumen tr {
  border-top: 1px solid #ccc;
}
.tabla-resumen td {
  padding: 1px 5px;
  border-right: 1px solid #ccc;
}
.tabla-resumen th {
  background: #f7f7f8;
  padding: 3px 5px;
}
.tabla-resumen .total-cell {
  text-align: right;
}
tr.tabla-resumen-b {
  border-bottom: 2px solid #aaa;
  background: var(--color-wt);
}
</style>